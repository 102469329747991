import { useState, useEffect, Fragment } from "react";
import { Button, ControlledDatePicker, Table, TableRow } from "../../ui";

// PROJECT IMPORT
import { useGetBalnceSummaryMutation } from "./balanceSheetApi";
import { useBalanceSummaryItems } from "./balanceSheetSlice";
import { FILTER } from "../../constant";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";

const BalanceSheet = () => {
  const [filter, setFilter] = useState(FILTER);
  const summaryItems = useBalanceSummaryItems();

  const [getBalanceSummary, { isLoading }] = useGetBalnceSummaryMutation();

  const columns = [
    { title: "Date", name: "payDate" },
    { title: "Deposit", name: "deposit" },
    { title: "Withdrawal", name: "withdrawal" },
    { title: "Un Settled", name: "unSettled" },
    { title: "Open Balance", name: "openAmt" },
    { title: "Closing Balance", name: "closingAmt" },
    { title: "Last Update", name: "last_updated_at" },
  ];

  const { handleSubmit, reset, getValues, control } = useForm({});

  const onGetData = () => {
    const values = getValues();
    let payload: any = {};
    if (filter?.isFilter) {
      payload = {
        ...filter,
        filter_data: {
          InitiateDate:
            new Date(
              new Date(values?.date?.[0]).setDate(
                new Date(+values?.date?.[0]).getDate() + 1
              )
            ) || null,
          FinalizeDate:
            new Date(
              new Date(values?.date?.[1]).setDate(
                new Date(+values?.date?.[1]).getDate() + 1
              )
            ) || null,
        },
      };
    } else {
      payload = { ...filter };
    }
    delete payload["isFilter"];
    getBalanceSummary(payload);
  };

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSubmit = async (values: any) => {
    setFilter({ ...FILTER, isFilter: true });
  };

  return (
    <>
      <form
        className="flex flex-wrap gap-2 items-end px-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <ControlledDatePicker
          name="date"
          label="Date"
          placeholder="Date"
          options={{
            mode: "range",
          }}
          control={control}
        />
        <Button type="submit" className="mt-[20px] !h-9 !text-sm">
          Apply
        </Button>
        <Button
          variant="Transaparent"
          className="mt-[20px] !h-9 !text-sm"
          onClick={() => {
            reset();
            setFilter({ ...FILTER });
          }}
        >
          Clear
        </Button>
      </form>

      <div className="App mt-3">
        <Table
          columns={columns}
          isLoading={isLoading}
          data={summaryItems?.data || []}
          count={summaryItems?.total_item}
          pagination={filter}
          isExpendable={false}
          handlePaginationChange={(pagination) => {
            setFilter({
              ...filter,
              ...pagination,
            });
          }}
        >
          {summaryItems?.data?.map((item: any, index: number) => {
            return (
              <Fragment key={index}>
                <TableRow columns={columns} isExpendable={false} item={item} />
              </Fragment>
            );
          })}
        </Table>
      </div>
    </>
  );
};

export default BalanceSheet;
