import { useEffect, useState, Fragment } from "react";

// UI IMPORT
import {
  Button,
  ControlledDatePicker,
  Icon,
  Input,
  Select,
  Table,
  TableRow,
  getStatusTypes,
} from "../../ui";

// PROJECT IMPORT
import { useDepositeData } from "./depositeSlice";
import {
  useGetDepositeMutation,
  useResendDepositWebhookMutation,
} from "./depositeApi";
import { depodataSummary, filterOptions } from "./mock";
import { FILTER } from "../../constant";
import { Card } from "../common";

// THIRD - PARTY IMPORT
import { useForm } from "react-hook-form";
import { statusOptions } from "../common/mock";
import CopyToClipboard from "react-copy-to-clipboard";
import { AiFillCopy } from "react-icons/ai";

const Deposite = () => {
  const depoData = useDepositeData();
  const [filter, setFilter] = useState(FILTER);
  const [getDeposite, { isLoading }] = useGetDepositeMutation();
  const [isError, setIsError] = useState<any>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [getresendDepositeWebhook] = useResendDepositWebhookMutation();

  const handleButtonClick = async (item: any) => {
    try {
      const payload = {
        TransactionId: item?.tranId,
      };
      await getresendDepositeWebhook(payload).unwrap();
    } catch (err) {}
  };

  const columns = [
    { title: "Date", name: "created_at" },
    { title: "Deposit ID", name: "tranId" },
    { title: "Payment Ref ID", name: "merchantOrdId" },
    { title: "Customer ID", name: "custId" },
    {
      title: "Status",
      name: "paymentSts",
      Cell: (data: any) => {
        return (
          <span className={`${getStatusTypes(data?.paymentSts)}`}>
            {data?.paymentSts}
          </span>
        );
      },
    },
    { title: "Amount", name: "paymentAmt", type: "₹ " },
    { title: "UTR", name: "utr" },
  ];

  const { register, handleSubmit, reset, getValues, control } = useForm({});

  const onGetData = async () => {
    const values = getValues();

    let payload: any = {};
    if (filter?.isFilter) {
      payload = {
        ...filter,
        filter_data: {
          InitiateDate:
            new Date(
              new Date(values?.date?.[0]).setDate(
                new Date(+values?.date?.[0]).getDate() + 1
              )
            ) || null,
          FinalizeDate:
            new Date(
              new Date(values?.date?.[1]).setDate(
                new Date(+values?.date?.[1]).getDate() + 1
              )
            ) || null,
          status: values.status,
          [values.tranId]: values?.search,
        },
      };
    } else {
      payload = {
        ...filter,
        filter_data: {
          status: "Success",
        },
      };
    }
    delete payload["isFilter"];
    getDeposite(payload);
  };

  useEffect(() => {
    if (
      (isError?.filter && isError?.value) ||
      (isError?.value === "" && isError?.value === "") ||
      isError?.value === undefined
    ) {
      onGetData();
    } else {
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    const IntervalClick = setInterval(() => {
      if (refresh) {
        setFilter({ ...filter });
      }
    }, 9000);
    return () => clearInterval(IntervalClick);
  }, [refresh]);

  const onSubmit = async () => {
    setFilter({ ...FILTER, isFilter: true });
  };

  return (
    <>
      <form
        className="flex flex-wrap gap-2 items-start px-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div>
          <div className="flex flex-wrap gap-2">
            <Select
              name="tranId"
              label="Filter"
              options={filterOptions}
              register={register}
              onChange={(e) =>
                setIsError({ ...isError, filter: e.target.value })
              }
            />
            <Input
              className="mr-2"
              name="search"
              placeholder="Enter Search Value"
              label="Search"
              register={register}
              onChange={(e) =>
                setIsError({ ...isError, value: e.target.value })
              }
            />
          </div>
          {!isError?.filter && isError?.value ? (
            <div className="text-red-500 mt-1">*Please Select Filter</div>
          ) : null}
        </div>
        <Select
          name="status"
          label="Status"
          register={register}
          options={statusOptions}
        />
        <ControlledDatePicker
          name="date"
          label="Date"
          placeholder="Date"
          options={{
            mode: "range",
          }}
          control={control}
        />
        <Button type="submit" className="mt-[22px] !h-9 !text-sm">
          Apply
        </Button>
        <Button
          variant="Transaparent"
          className="mt-[22px]  !text-sm !h-9"
          onClick={() => {
            reset();
            setFilter({ ...FILTER });
          }}
        >
          Clear
        </Button>
        <Button
          type="button"
          title="Auto Refresh"
          variant={refresh ? "Primary" : "Transaparent"}
          className={`mt-[22px] !text-sm !h-9 ${
            refresh ? "hover:bg-chatlook-darkblue" : "hover:bg-white"
          }`}
          onClick={() => setRefresh(!refresh)}
        >
          <Icon
            name="Refresh"
            className={` ${refresh ? "animate-spin" : ""}`}
          />
        </Button>
      </form>

      <div className="mt-2">
        <div className="grid grid-cols-4 sm:grid-cols-2 md:grid-cols-2 gap-4">
          {depodataSummary?.map((item: any, index) => (
            <Fragment key={index}>
              <Card
                index={index}
                value={+depoData?.summary?.[item.key]}
                text={item?.text}
                icon={item?.imgSrc}
                rupee={item?.rupee}
              />
            </Fragment>
          ))}
        </div>
        <div className="App mt-3">
          <Table
            columns={columns}
            isLoading={isLoading}
            data={depoData?.data}
            count={depoData?.total_item}
            pagination={filter}
            handlePaginationChange={(pagination) => {
              setFilter({
                ...filter,
                ...pagination,
              });
            }}
          >
            {depoData?.data?.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <TableRow columns={columns} item={item}>
                    <div className="flex justify-around py-2 ">
                      <div>
                        <h3 className="text-sm  mb-2 text-chatlook-darkblue ">
                          Transcation detail
                        </h3>
                        <div className="bg-chatlook-white py-4 px-7 rounded-md text-sm">
                          <div className="flex justify-between items-center mb-3">
                            <div className="flex">
                              <p className="font-medium text-[#747070]">
                                Deposit ID :
                              </p>
                              <p className="ps-2">{item?.tranId}</p>
                            </div>
                            <p className="ps-4">
                              <CopyToClipboard text={item?.tranId}>
                                <AiFillCopy className="cursor-pointer" />
                              </CopyToClipboard>
                            </p>
                          </div>
                          <div className="flex justify-between items-center mb-3">
                            <div className="flex">
                              <p className="font-medium text-[#747070]">
                                UTR :{" "}
                              </p>
                              <p className="ps-2">{item?.utr}</p>
                            </div>
                            <p className="ps-4">
                              <CopyToClipboard text={item?.utr}>
                                <AiFillCopy className="cursor-pointer" />
                              </CopyToClipboard>
                            </p>
                          </div>
                          <div className="flex justify-between items-center ">
                            <div className="flex">
                              <p className="font-medium text-[#747070]">
                                Payment Ref ID:
                              </p>
                              <p className="ps-2">{item?.merchantOrdId}</p>
                            </div>
                            <p className="ps-4">
                              <CopyToClipboard text={item?.merchantOrdId}>
                                <AiFillCopy className="cursor-pointer" />
                              </CopyToClipboard>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="text-sm ">
                        <div className="mb-2.5">
                          <h5 className="font-medium text-[#747070]">
                            Customer ID
                          </h5>
                          <p>{item?.custId}</p>
                        </div>
                        <div className=" mb-2.5">
                          <h5 className="font-medium text-[#747070]">Email</h5>
                          <p>{item?.custEmail || "-"}</p>
                        </div>
                        <div>
                          <h5 className="font-medium text-[#747070]">Phone</h5>
                          <p>{item?.custMobile || "-"}</p>
                        </div>
                      </div>
                      <div>
                        <div className="mb-2.5">
                          <h5 className="font-medium text-[#747070]">
                            PG Fees
                          </h5>
                          <p>₹ {item?.pgFees}</p>
                        </div>
                        <div className=" mb-2.5">
                          <h5 className="font-medium text-[#747070]">
                            Settled{" "}
                          </h5>
                          <p>₹ {item?.payableAmt}</p>
                        </div>
                        <div>
                          <h5 className="font-medium text-[#747070]">
                            Success Date
                          </h5>
                          <p>{item?.SuccessDate || "-"}</p>
                        </div>
                      </div>
                      <div className="grid">
                        <div>
                          <h3 className="text-sm  mb-2">Amount</h3>
                          <p className="text-2xl font-bold  text-chatlook-darkblue">
                            ₹ {item?.paymentAmt}
                          </p>
                        </div>
                        <Button
                          // type="submit"
                          className="!text-sm h-8"
                          onClick={() => handleButtonClick(item)}
                        >
                          Resend Webhook
                        </Button>
                      </div>
                    </div>
                  </TableRow>
                </Fragment>
              );
            })}
          </Table>
        </div>
      </div>
    </>
  );
};

export default Deposite;
